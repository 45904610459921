import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';
import { PriorityLink } from './CustomLinksMenu/PriorityLinks';

import css from './TopbarDesktop.module.css';
import { createNotificationBadge } from '../TopbarMobileMenu/TopbarMobileMenu';

const SignupLink = () => {
  return (
    <NamedLink name="SignupPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );
};

const LoginLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
};

// const InboxLink = ({ notificationCount, currentUserHasListings }) => {
//   const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

//   return (
//     <NamedLink
//       className={css.topbarLink}
//       name="InboxPage"
//       params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
//     >
//       <span className={css.topbarLinkLabel}>
//         <FormattedMessage id="TopbarDesktop.inbox" />
//         {notificationDot}
//       </span>
//     </NamedLink>
//   );
// };
const draftId = '00000000-0000-0000-0000-000000000000';

const ProfileMenu = ({
  currentPage,
  currentUser,
  onLogout,
  intl,
  notificationCount,
  currentUserHasListings,
}) => {
  const notificationDot =
    notificationCount > 0 ? <div className={css.profileNotificationDot} /> : null;

  const notificationBadge = createNotificationBadge(notificationCount, css.notificationCountBadge);

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);

    const isInboxPage = page === 'InboxPage' && currentPage?.includes('InboxPage');

    return currentPage === page || isAccountSettingsPage || isInboxPage ? css.currentPage : null;
  };

  const newListingConfig = {
    group: 'primary',
    text: intl.formatMessage({ id: 'TopbarDesktop.createListing' }),
    type: 'internal',
    route: {
      name: 'EditListingPage',
      params: { slug: 'draft', id: draftId, type: 'new', tab: 'details' },
    },
    highlight: true,
  };

  const isSupplier =
    currentUser && currentUser?.attributes?.profile?.publicData?.storeFrontId === undefined;

  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        {notificationDot}
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key={'InboxPage'}>
          <NamedLink
            name={'InboxPage'}
            className={classNames(css.menuLink, currentPageClass('InboxPage'))}
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox" />
            {notificationBadge}
          </NamedLink>
        </MenuItem>
        {/* 
          Cannot have a null child in a MenuContent, so used CSS to conditionally hide 
          "New Listing" and "Your Offers" link for buyers instead of using ternary to replace with null
        */}
        <MenuItem key={newListingConfig.text}>
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('EditListingPage'))}
            name={newListingConfig.route.name}
            params={newListingConfig.route.params}
            style={!isSupplier ? { display: 'none' } : undefined} // Hide for buyers
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.createListing" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
            style={!isSupplier ? { display: 'none' } : undefined} // Hide for buyers
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const TopbarDesktop = props => {
  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    // onSearchSubmit,
    // initialSearchFormValues,
    storeLogo,
    aspectRatio,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || css.root, className);

  // const inboxLinkMaybe = authenticatedOnClientSide ? (
  //   <InboxLink
  //     notificationCount={notificationCount}
  //     currentUserHasListings={currentUserHasListings}
  //   />
  // ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu
      currentPage={currentPage}
      currentUser={currentUser}
      onLogout={onLogout}
      intl={intl}
    />
  ) : null;

  const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : (
    <SignupLink fromLandingPage={props.currentPage === 'LandingPage'} />
  );
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;
  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        linkToExternalSite={config?.topbar?.logoLink}
        storeLogo={storeLogo}
        aspectRatio={aspectRatio}
      />
      {/* 
      Remove Search Bar
      <TopbarSearchForm
        className={classNames(css.searchLink, { [css.takeAvailableSpace]: giveSpaceForSearch })}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        onSubmit={onSearchSubmit}
        initialValues={initialSearchFormValues}
        appConfig={config}
  
      Custom Links is no longer needed as About page is hidden and New Offer link is in the Profile Menu
      <CustomLinksMenu
        currentPage={currentPage}
        customLinks={customLinks}
        intl={intl}
        hasClientSideContentReady={authenticatedOnClientSide || !isAuthenticatedOrJustHydrated}
      /> */}

      <div className={css.navLinkContainer}>
        {/* {inboxLinkMaybe} */}
        {profileMenuMaybe}
        {signupLinkMaybe}
        {loginLinkMaybe}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
